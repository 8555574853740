import React from 'react';
import ReactDOM from 'react-dom';
import {
    BrowserRouter
} from "react-router-dom";

import App from './App';
import * as serviceWorker from './serviceWorker';

import 'bootstrap/dist/css/bootstrap.min.css';
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all";
import "./scss/style.scss";

const rootElement = document.getElementById("root");
const application = (
    <React.StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </React.StrictMode>
);
if(rootElement !== null) {
    if (rootElement.hasChildNodes()) {
        ReactDOM.hydrate(application, rootElement);
    } else {
        ReactDOM.render(application, rootElement);
    }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

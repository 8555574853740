import React from 'react';
import {
    Switch,
    Redirect,
    Route
} from "react-router-dom";
import ScrollToTop from "./hooks/ScrollToTop";
import useTracking from "./hooks/TrackingHook";

import CookieConsentBanner from "./components/CookieConsentBanner";
import PaintNavbar from "./components/PaintNavbar";
import Footer from "./components/Footer";

import Home from "./pages/Home";
import Changelog from "./pages/Changelog";
import PressKit from "./pages/PressKit";
import Page404 from "./pages/Page404";

function App() {
    useTracking("UA-69123742-7");
    return (
        <React.Fragment>
            <PaintNavbar/>

            <ScrollToTop/>

            <Switch>
                <Route path="/" component={Home} exact/>
                <Route path="/changelog" component={Changelog}/>
                <Route path="/presskit" component={PressKit}/>

                <Route path={ ["/index", "/index.html", "/index.htm", "/index.php"] }>
                    <Redirect to="/"/>
                </Route>

                <Route path="/" component={Page404}/>
            </Switch>

            <CookieConsentBanner/>

            <Footer/>
        </React.Fragment>
    );
}

export default App;

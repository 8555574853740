import React, {
    useEffect,
    useState
} from "react";
import {
    Container
} from "react-bootstrap";
import ReactMarkdown from "react-markdown";

import PageJumbotron from "../components/PageJumbotron";
import Helmet from "../components/Helmet";

function Changelog() {
    const [changelog, setChangelog] = useState<string | null>(null);

    useEffect(() => {
        fetch("/changelogs/prerelease-427-476.md")
            .then(function(response){
                return response.text();
            }).then(function (data) {
                setChangelog(data);
            });
    }, []);

    return (
        <React.Fragment>
            <Helmet title="Changelog"/>
            <PageJumbotron title="Changelog"/>
            <Container>
                { changelog && (
                    <ReactMarkdown>
                        { changelog }
                    </ReactMarkdown>
                )}
            </Container>
        </React.Fragment>
    );
}

export default React.memo(Changelog);

import React from "react";
import {
    Col,
    Container,
    Nav,
    Row
} from "react-bootstrap";
import YouTube from "react-youtube";
import Helmet from "../components/Helmet";
import HomeJumbotron from "./../components/HomeJumbotron";

import buasLogo from "./../img/buas.svg";
import igadLogo from "./../img/igad.svg";
import unrealLogo from "./../img/unreal.svg";

type SocialIconLinkProps = {
    icon: string,
    link: string
}
function SocialIconLink(props: SocialIconLinkProps) {
    return (
        <Nav.Item>
            <Nav.Link href={props.link} target="_blank" rel="noopener noreferrer">
                <i className={ "fab fa-" + props.icon } />
            </Nav.Link>
        </Nav.Item>
    );
}

function Home() {
    return (
        <React.Fragment>
            <Helmet/>
            <HomeJumbotron/>
            <Container className="pl-md-3 pr-md-3 text-center">
                <p style={{ fontSize: "1.2rem" }}>
                    You are P.U.N.K.’s. Rebels that together fight the tyrannical grasp of the evil corporations with
                    the thing they hate most; Colour.
                </p>
                <p style={{ fontSize: "1.2rem" }}>
                    However, the corporations will not go down without a fight. Their CEO has put out a high bonus for
                    anyone in their corporation who brings down a P.U.N.K. In response to this, your association has
                    devised special paint blasters that fire paint at high speed and allows you to combat the
                    Corporations’ evil loyalists that try to bring you down.
                </p>
            </Container>
            <Container className="mt-5 mb-5 text-center">
                <Row>
                    <Col md={{ span: 8, offset: 2}}>
                        <YouTube
                            videoId="Xfq4s2TMg7g"
                            className="embed-responsive-item"
                            iframeClassName="embed-responsive embed-responsive-16by9"
                            opts={{ playerVars: { rel: 0 } }}
                        />
                    </Col>
                </Row>
            </Container>
            <Container className="mt-5 mb-5 text-center">
                <h1>Follow us</h1>
                <Nav className="d-flex justify-content-center" style={{ fontSize: "2.5rem" }}>
                    <SocialIconLink icon="steam" link="https://store.steampowered.com/app/1175890/PAINT"/>
                    <SocialIconLink icon="discord" link="https://discord.gg/kx6z4GX"/>
                    <SocialIconLink icon="reddit" link="https://reddit.com/r/PAINT_GAME"/>
                    <SocialIconLink icon="instagram" link="https://instagram.com/paintgame2020"/>
                    <SocialIconLink icon="facebook-square" link="https://facebook.com/PaintGame2020"/>
                </Nav>
            </Container>
            <div className="mt-5" style={{ backgroundColor: "#F5F5F5", marginBottom: "-25px" }}>
                <Container className="pt-5 pb-5 text-center">
                    <Row>
                        <Col md={ 3 } lg={ 4 } className="mb-4 mb-md-0">
                            <a href="https://unrealengine.com" target="_blank" rel="noreferrer noopener">
                                <img src={ unrealLogo } className="img-fluid" style={{ maxHeight: "100px" }} alt="Unreal Engine"/>
                            </a>
                        </Col>
                        <Col md={ 6 } lg={ 4 } className="mb-4 mb-md-0">
                            <a href="https://buas.nl" target="_blank" rel="noreferrer noopener">
                                <img src={ buasLogo } className="img-fluid" style={{ maxHeight: "100px" }} alt="Breda University of Applied Sciences"/>
                            </a>
                        </Col>
                        <Col md={ 3 } lg={ 4 }>
                            <a href="https://buas.nl/en/programmes/creative-media-and-game-technologies" target="_blank" rel="noreferrer noopener">
                                <img src={ igadLogo } className="img-fluid" style={{ maxHeight: "100px" }} alt="Creative Media and Game Technologies"/>
                            </a>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default Home;

import React from "react";
import {
    Col,
    Container,
    Jumbotron,
    Row
} from "react-bootstrap";

import logo from "./../img/paint-logo.png";
import banner from "./../img/paint-banner.png";
import bannerMobile from "./../img/paint-banner-mobile.png";

function HomeJumbotron() {
    const content = (
        <Container>
            <Row>
                <Col xs={ 12 } sm={ 7 } md={ 6 }>
                    <div className="d-flex align-items-end align-md-items-center justify-content-center justify-content-md-start" style={{ minHeight: "50vh" }}>
                        <img src={ logo } alt="P.A.I.N.T." className="d-none d-md-block" style={{ width: "100%" }}/>
                        <a href="https://store.steampowered.com/app/1175890/PAINT" className="btn btn-primary btn-lg d-md-none" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-steam"/> Early Access on Steam
                        </a>
                    </div>
                </Col>
            </Row>
        </Container>
    );
    return (
        <React.Fragment>
            <Jumbotron fluid className="text-white bg-dark-pink d-none d-md-block" style={{
                backgroundImage: "url(" + banner + ")",
                backgroundPosition: "center",
                backgroundSize: "auto 100%",
                backgroundRepeat: "no-repeat"
            }}>
                { content }
            </Jumbotron>
            <Jumbotron fluid className="text-white bg-dark-pink d-md-none" style={{
                backgroundImage: "url(" + bannerMobile + ")",
                backgroundPosition: "center",
                backgroundSize: "auto 100%",
                backgroundRepeat: "no-repeat"
            }}>
                { content }
            </Jumbotron>
        </React.Fragment>
    );
}

export default HomeJumbotron;

import React from "react";
import {
    Card,
    Col,
    Container,
    Row
} from "react-bootstrap";
import {
    CardProps
} from "react-bootstrap/Card";
import PageJumbotron from "../components/PageJumbotron";
import Helmet from "../components/Helmet";

import iconBlack from "./../presskit/PAINT-icon-black.png";
import iconBlackSingle from "./../presskit/PAINT-icon-single-black.png";
import iconWhite from "./../presskit/PAINT-icon-white.png";
import iconWhiteSingle from "./../presskit/PAINT-icon-single-white.png";
import logoBlack from "./../presskit/PAINT-logo-black.png";
import logoWhite from "./../presskit/PAINT-logo-white.png";

type FactSheetItemProps = {
    title: string,
    children: any
}
function FactSheetItem(props: FactSheetItemProps) {
    return (
        <li>
            <b>{ props.title }:</b>{" "}
            { props.children }
        </li>
    )
}

type CreditsProps = {
    name: string,
    variation: "design" | "programming" | "production" | "art" | "other",
    link?: string
    linkTitle?: string,
}
function CreditsItem(props: CreditsProps) {
    let border: CardProps["border"] = "primary";
    switch(props.variation) {
        case "design": border = "danger"; break;
        case "programming": border = "info"; break;
        case "production": border = "danger"; break;
        case "art": border = "success"; break;
        case "other": border = "primary"; break;
    }
    let hasLink: boolean = props.link !== undefined && props.linkTitle !== undefined;
    return (
        <Col md={ 4 }>
            <Card body border={ border } className="text-center mb-3">
                <Card.Title className={ (!hasLink ? " mb-1" : "") }>
                    { props.name }
                </Card.Title>
                { hasLink && (
                    <a href={ props.link } className="card-link" target="_blank" rel="noopener noreferrer">
                        { props.linkTitle }
                    </a>
                )}
            </Card>
        </Col>
    )
}

function PressKit() {
    return (
        <React.Fragment>
            <Helmet title="PressKit"/>
            <PageJumbotron title="PressKit"/>

            <Container>
                <p>
                    By Plankwalk Studio.<br/>
                    In association with Breda University of Applied Sciences.
                </p>

                <h2>Fact Sheet</h2>
                <ul>
                    <FactSheetItem title="Developer">Plankwalk Studio</FactSheetItem>
                    <FactSheetItem title="Publisher">Breda University of Applied Sciences</FactSheetItem>
                    <FactSheetItem title="Release Date">
                        June 23th 2020{" "}
                        (<a href="https://store.steampowered.com/app/1175890/PAINT" target=" _blank" rel="noopener noreferrer">Steam</a>)
                    </FactSheetItem>
                    <FactSheetItem title="Platform">PC</FactSheetItem>
                    <FactSheetItem title="Website"><a href="https://paint-game.com">paint-game.com</a></FactSheetItem>
                    <FactSheetItem title="Price">Free</FactSheetItem>
                    <FactSheetItem title="Availability">Digital Downloader</FactSheetItem>
                    <FactSheetItem title="Languages">English</FactSheetItem>
                    <FactSheetItem title="File Size">~4 GB</FactSheetItem>
                    <FactSheetItem title="Press Contact">
                        Damiaan van Citters{" "}
                        (<a href="mailto:170894@buas.nl">170894@buas.nl</a>)
                    </FactSheetItem>
                    <FactSheetItem title="Social">
                        <a href="https://discord.gg/kx6z4GX" target=" _blank" rel="noopener noreferrer">Discord</a>{", "}
                        <a href="https://instagram.com/paintgame2020" target=" _blank" rel="noopener noreferrer">Instagram</a>{", "}
                        <a href="https://facebook.com/PaintGame2020" target=" _blank" rel="noopener noreferrer">Facebook</a>
                    </FactSheetItem>
                </ul>
            </Container>

            <Container>
                <h2>Description</h2>
                <p>
                    You are P.U.N.K.’s. Rebels that together fight the tyrannical grasp of the evil corporations with
                    the thing they hate most; Colour.
                </p>
                <p>
                    However, the corporations will not go down without a fight. Their CEO has put out a high bonus for
                    anyone in their corporation who brings down a P.U.N.K. In response to this, your association has
                    devised special paint blasters that fire paint at high speed and allows you to combat the
                    Corporations’ evil loyalists that try to bring you down.
                </p>
            </Container>

            <Container>
                <h2>Features</h2>
                <ul>
                    <li>Play co-op with up to four players. Jump in a game and work together to improve your highscores.</li>
                    <li>Invite your steam friends to your party and play together.</li>
                    <li>A HUB area where you can gather with your friends before a match. Target practice is one of the awesome functions of the HUB.</li>
                    <li>Highscores, together you can defeat enemies and complete objectives to reach the new highscore.</li>
                    <li>Global Leaderboard, compare your score with others around the world.</li>
                    <li>Paint. You’ll be able to paint the environment the way you want with the paint blaster.</li>
                </ul>
            </Container>

            <Container>
                <h2>Plankwalk Studio</h2>
                <p>
                    Plankwalk Studio was founded in 2019 as part of Breda University of Applied Sciences.
                </p>
                <p>
                    A team of 30 third-year students was formed at the start of the 2019/2020 academic year. All the
                    students are part of the course International Game Architecture & Design. The project brief that was
                    provided gave the students the task to create an online co-op game. The team got a full academic
                    year before the game had to be released on the gaming platform Steam.
                </p>
                <p>
                    During the project the team got support from the concept art outsource and technical art outsource
                    team from Breda University of Applied Sciences. New team members got added and left in the duration
                    of development.
                </p>
                <p>
                    We love to get together as a team and play games. Exactly this experience is what strives us to
                    create a new epic co-op game. We love what we create and create what we love.
                </p>
            </Container>

            <Container>
                <h2>Game Art</h2>
                <p>
                    View & download P.A.I.N.T. art from <a href="https://drive.google.com/open?id=1iPE-Z3aIZmbzefmt66AKC4SIoLmxgrgV" target="_blank" rel="noopener noreferrer">Google Drive</a>.
                </p>
            </Container>

            <Container>
                <h2>Logos and Icons</h2>
                <Row>
                    <Col md={ 3 }>
                        <Card className="mb-4">
                            <img src={iconBlack} className="img-fluid" alt=""/>
                        </Card>
                    </Col>
                    <Col md={ 3 }>
                        <Card className="mb-4" bg="dark">
                            <img src={iconWhite} className="img-fluid" alt=""/>
                        </Card>
                    </Col>
                    <Col md={ 3 }>
                        <Card className="mb-4">
                            <img src={iconBlackSingle} className="img-fluid" alt=""/>
                        </Card>
                    </Col>
                    <Col md={ 3 }>
                        <Card className="mb-4" bg="dark">
                            <img src={iconWhiteSingle} className="img-fluid" alt=""/>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card className="mb-4">
                            <img src={logoBlack} className="img-fluid m-3" alt=""/>
                        </Card>
                    </Col>
                    <Col>
                        <Card className="mb-4" bg="dark">
                            <img src={logoWhite} className="img-fluid m-3" alt=""/>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <Container>
                <h2>Credits</h2>

                <h3>Production</h3>
                <Row>
                    <CreditsItem variation="production" name="Damiaan van Citters" link="https://damiaanvancitters.com" linkTitle="Portfolio"/>
                </Row>

                <h3>Art</h3>
                <Row>
                    <CreditsItem variation="art" name="Anette Vávrová" link="https://artstation.com/anettevavrova" linkTitle="ArtStation"/>
                    <CreditsItem variation="art" name="Max Vetter"/>
                    <CreditsItem variation="art" name="Quincy Daflaar"/>
                    <CreditsItem variation="art" name="Renske van Alebeek" link="https://artstation.com/renskeva" linkTitle="ArtStation"/>
                    <CreditsItem variation="art" name="Robin van den Eerenbeemd" link="https://robinvandeneerenbeemd.com" linkTitle="Portfolio"/>
                    <CreditsItem variation="art" name="Ronny van Kuijk" link="https://artstation.com/ronnyvankuijk" linkTitle="ArtStation"/>
                    <CreditsItem variation="art" name="Răzvan Cercel"/>
                    <CreditsItem variation="art" name="Viktoria Vasileva" link="https://artstation.com/victoriavasileva" linkTitle="ArtStation"/>
                    <CreditsItem variation="art" name="Yana Tosheva" link="https://artstation.com/yanaadt" linkTitle="ArtStation"/>
                </Row>

                <h3>Design</h3>
                <Row>
                    <CreditsItem variation="design" name="Georgi Simov" link="https://georgisimov-ld.com" linkTitle="Portfolio"/>
                    <CreditsItem variation="design" name="Gregor de Weger" link="https://gregordeweger.com" linkTitle="Portfolio"/>
                    <CreditsItem variation="design" name="Mees Wendels" link="https://meeswendels.com" linkTitle="Portfolio"/>
                    <CreditsItem variation="design" name="Menno Verdonk"/>
                    <CreditsItem variation="design" name="Roy Bomhof" link="https://roybomhof.nl" linkTitle="Portfolio"/>
                    <CreditsItem variation="design" name="Ruben Spaander" link="https://rubenspaander.com" linkTitle="Portfolio"/>
                </Row>

                <h3>Programming</h3>
                <Row>
                    <CreditsItem variation="programming" name="Arthur Kuylaars" link="https://arthurcodes.com" linkTitle="Portfolio"/>
                    <CreditsItem variation="programming" name="Corne Steenhuis" link="https://csteenhuis.com" linkTitle="Portfolio"/>
                    <CreditsItem variation="programming" name="Erico Wiggers" link="https://ericowiggers.com" linkTitle="Portfolio"/>
                    <CreditsItem variation="programming" name="Niels van Steeg"/>
                    <CreditsItem variation="programming" name="Wouter Grutter" link="https://woutergrutter.com" linkTitle="Portfolio"/>
                    <CreditsItem variation="programming" name="Zandor Smith" link="https://zandorsmith.com" linkTitle="Portfolio"/>
                </Row>

                <h3>Outsource Concept Art</h3>
                <Row>
                    <CreditsItem variation="other" name="Bram Lepelaar"/>
                    <CreditsItem variation="other" name="Nadia de Waal"/>
                    <CreditsItem variation="other" name="Salomé Busurashvili"/>
                </Row>

                <h3>Outsource Technical Art</h3>
                <Row>
                    <CreditsItem variation="other" name="Daniélle Viljoen" link="https://artstation.com/dviljoen" linkTitle="ArtStation"/>
                    <CreditsItem variation="other" name="Laura Bot"/>
                    <CreditsItem variation="other" name="Radoslav Chardakov"/>
                    <CreditsItem variation="other" name="Sheila Stipnieks"/>
                </Row>

                <h3>Outsource Marketing</h3>
                <Row>
                    <CreditsItem variation="other" name="Melissa Ernzerhoff"/>
                </Row>

                <h3>Teaching Staff</h3>
                <Row>
                    <CreditsItem variation="other" name="Ard Bonewald"/>
                    <CreditsItem variation="other" name="Chris Rothwell"/>
                    <CreditsItem variation="other" name="David Hörchner"/>
                    <CreditsItem variation="other" name="Elwin Verploegen"/>
                    <CreditsItem variation="other" name="Lothar Zhou"/>
                    <CreditsItem variation="other" name="Paul Mertens"/>
                    <CreditsItem variation="other" name="Peter van Dranen"/>
                    <CreditsItem variation="other" name="Susanne Sexton"/>
                    <CreditsItem variation="other" name="Zoran Arizanovic"/>
                    <CreditsItem variation="other" name="Karena Morrison"/>
                    <CreditsItem variation="other" name="Martin Beresford"/>
                    <CreditsItem variation="other" name="Pim Hendriks"/>
                    <CreditsItem variation="other" name="Ralph Palmer"/>
                    <CreditsItem variation="other" name="Jonathan van der Wijngaarden"/>
                    <CreditsItem variation="other" name="Abhishek Biswas"/>
                    <CreditsItem variation="other" name="Jeanny van Zon"/>
                </Row>

                <h3>Thanks to</h3>
                <Row>
                    <CreditsItem variation="other" name="Aleksandar Radulov"/>
                    <CreditsItem variation="other" name="Bjorn Prins"/>
                    <CreditsItem variation="other" name="Dennis Verschuur"/>
                    <CreditsItem variation="other" name="Eric Drößiger"/>
                    <CreditsItem variation="other" name="Hristo Enchev"/>
                    <CreditsItem variation="other" name="Joan Tassov"/>
                    <CreditsItem variation="other" name="Matthijs Dijkman"/>
                    <CreditsItem variation="other" name="Max Helmus"/>
                    <CreditsItem variation="other" name="Robbert Spijkstra"/>
                    <CreditsItem variation="other" name="Tom Schuller"/>
                    <CreditsItem variation="other" name="Wouter Bernards"/>
                </Row>
            </Container>

        </React.Fragment>
    );
}

export default PressKit;

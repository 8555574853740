import React from "react";
import {
    Container,
    Jumbotron
} from "react-bootstrap";

type PageJumbotronProps = {
    title: string
}

function PageJumbotron(props: PageJumbotronProps) {
    return (
        <React.Fragment>
            <Jumbotron fluid className="text-white bg-dark-pink text-center">
                <Container>
                    <h1>{ props.title }</h1>
                </Container>
            </Jumbotron>
        </React.Fragment>
    );
}

export default PageJumbotron;

import React from "react";
import MetaTags from "react-meta-tags";

import capsuleImage from "../img/capsule.jpg";

function Helmet({
    title = undefined,
    siteName = "P.A.I.N.T.",
    description = "In this cooperative third-person shooter, you take control of the P.U.N.K.’s. Rebels that shoot their way through an evil factory with high-powered paint blasters. Sabotage the tyrannical corporation and fight your way through their factory to achieve the highest chaos score!",
    slogan = "The Game",
    image = capsuleImage
}) {
    let pageTitle = siteName + " - " + slogan;
    if(title !== undefined) {
        pageTitle = title + " - " + siteName;
    }
    return (
        <MetaTags>
            <title>{ pageTitle }</title>
            <meta name="title" content={ pageTitle } />
            <meta property="og:title" content={ pageTitle } />
            <meta name="twitter:title" content={ pageTitle } />
            <meta name="description" content={ description } />
            <meta property="og:description" content={ description } />
            <meta name="twitter:description" content={ description } />
            <meta name="summary" content={ description } />
            <meta property="og:image" content={ image } />
            <meta name="twitter:image" content={ image } />
            <meta name="og:site_name" content={ siteName }/>
        </MetaTags>
    )
}

export default React.memo(Helmet);

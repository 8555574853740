import React from "react";
import {
    Container,
    Nav,
    Navbar
} from "react-bootstrap";
import {
    Link
} from "react-router-dom";

import logo from "./../img/paint-logo.png";

type NavbarLinkProps = {
    to: string,
    title: string
}
function NavbarLink(props: NavbarLinkProps) {
    return (
        <li className="nav-item active">
            <Link className="nav-link" to={ props.to }>{ props.title }</Link>
        </li>
    );
}

function PaintNavbar() {
    return (
        <React.Fragment>
            <div style={{ height: "76px" }}/>
            <Navbar fixed="top" variant="dark" className="bg-dark-pink">
                <Container>
                    <Navbar.Brand href="/">
                        <img
                            alt="P.A.I.N.T."
                            src={ logo }
                            height="50"
                            className="d-inline-block align-top"
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto"/>
                        <Nav>
                            <NavbarLink to="/" title="Home"/>
                            <NavbarLink to="/presskit" title="Presskit"/>
                            <a href="https://store.steampowered.com/app/1175890/PAINT" className="btn btn-primary d-none d-md-block ml-3" target="_blank" rel="noopener noreferrer">
                                <i className="fab fa-steam"/> Early Access on Steam
                            </a>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </React.Fragment>
    );
}

export default PaintNavbar;
